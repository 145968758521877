'use client'

// next core
import { redirect } from 'next/navigation'

// react core
import { useEffect } from 'react'

// redux core
import { useSelector, selectIsAuthenticated } from '@/store/redux'

export default function NotFound() {
   const isAuthenticated = useSelector(selectIsAuthenticated)

   useEffect(() => {
      if (isAuthenticated === null) {
         redirect('/authorise')
      } else if (isAuthenticated !== null && isAuthenticated) {
         redirect('/home')
      } else if (isAuthenticated !== null && !isAuthenticated) {
         redirect('/signin')
      }
   }, [isAuthenticated])

   return <></>
}
